





























































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import { uuid } from "@/tool";
import { queryClassRoom, setClassRoom } from "@/api/classRoom";
import { ClassRoom, School, UpgradeLevel } from "@/tool/interface-index";
import { getSchoolList } from "@/api/school";
@Component({
  name: "CreateClassRoom",
  components: {
    cardBox
  }
})
export default class extends Vue {
  private ruleForm: ClassRoom = {
    classRoomId: uuid(),
    classRoomName: "",
    schoolId: "",
    contactPerson: "",
    contactPersonPhone: "",
    contactPersonEmail: "",
    contactPerson02: "",
    contactPersonPhone02: "",
    contactPersonEmail02: "",
    registrationCode: "",
    softwareVersion: "",
    upgradeLevel: UpgradeLevel.none
  };
  rules = {
    classRoomName: [
      { required: true, message: "请输入教室名称", trigger: "change" }
    ],
    schoolId: [{ required: true, message: "请选择学校", trigger: "change" }],
    contactPerson: [
      { required: true, message: "请输入联系人名称", trigger: "change" }
    ],
    contactPersonEmail: [
      { required: true, message: "请输入联系人邮箱", trigger: "change" }
    ],
    contactPersonPhone: [
      { required: true, message: "请输入联系人电话", trigger: "change" }
    ],
    registrationCode: [
      { required: true, message: "请输入注册码", trigger: "change" }
    ]
  };
  private pageType: string = "";
  private title: string = "";
  private schoolData: Array<School> = [];

  private get type(): string {
    if (this.pageType == "add") {
      this.title = "创建教室";
      return "立即创建";
    }
    if (this.pageType == "edit") {
      this.title = "编辑教室";
      return "保 存";
    }
    return "立即创建";
  }
  submitForm() {
    (this as any).$refs.ruleForm.validate((valid: any) => {
      if (valid) {
        setClassRoom(this.ruleForm).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "创建成功!"
            });
          } else {
            this.$message({
              type: "warning",
              message: res.msg
            });
          }
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  doGoBackClick() {
    this.$router.go(-1);
  }
  resetForm(formName: string | number) {
    (this as any).$refs.ruleForm.resetFields();
  }
  private getSchoolData() {
    getSchoolList({
      pageSize: 9999,
      curPage: 1
    }).then((res) => {
      this.schoolData = res.data.items;
    });
  }
  mounted() {
    if (this.$route.query.classRoomId) {
      queryClassRoom(this.$route.query.classRoomId as string).then((res: any) => {
        this.ruleForm = res.data;
      });
    }
    this.pageType = this.$route.query.type as string;
    this.getSchoolData();
  }
}
